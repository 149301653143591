import React from "react";
import {
  AppBar,
  Container,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { navigate } from "gatsby";
import NotInterestedIcon from "@material-ui/icons/NotInterested";

const useStyles = makeStyles((theme) => ({
  bannerRoot: {
    height: theme.spacing(6),
  },
  allHeight: {
    height: "100%",
  },
  grid: {
    height: "100%",
    alignItems: "center",
  },
  link: {
    cursor: "pointer",
  },
}));

export function TopBanner() {
  const classes = useStyles();
  return (
    <AppBar position="static" className={classes.bannerRoot}>
      <Container fixed className={classes.allHeight}>
        <Grid container className={classes.grid}>
          <Grid item onClick={() => navigate("/")} className={classes.link}>
            <Typography variant="h5" component="h1">
              No
            </Typography>
          </Grid>
          <Grid
            item
            style={{ alignSelf: "center" }}
            onClick={() => navigate("/")}
            className={classes.link}
          >
            <NotInterestedIcon
              color="error"
              style={{ verticalAlign: "bottom" }}
            />
          </Grid>
          <Grid item onClick={() => navigate("/")} className={classes.link}>
            <Typography variant="h5" component="h1">
              Shortcut
            </Typography>
          </Grid>
          <Grid item onClick={() => navigate("/")} className={classes.link}>
            <Typography variant="caption">
              &nbsp;&mdash;&nbsp;a developer's blog
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </AppBar>
  );
}
